import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy, useState } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import { AppContext } from "./lib/contextLib";

import { HelmetProvider } from 'react-helmet-async';

// home pages
const HomeIndex = lazy(() => import("./pages/home/HomeIndex"));
const MiCuenta = lazy(() => import("./pages/home/MiCuenta"));

// shop pages
const Catalogo = lazy(() => import("./pages/shop/Catalogo"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));
const ProductTabLeft = lazy(() => import("./pages/shop-product/ProductTabLeft"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Nosotros = lazy(() => import("./pages/other/Nosotros"));
const Contact = lazy(() => import("./pages/other/Contact"));
const Videos = lazy(() => import("./pages/other/Videos"));
const Cursos = lazy(() => import("./pages/other/Cursos"));
const Eventos = lazy(() => import("./pages/other/Eventos"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const PagoAprobado = lazy(() => import("./pages/other/PagoAprobado"));
const Politicas = lazy(() => import("./pages/other/Politicas"));
const Login = lazy(() => import("./pages/other/Login"));
const Registro = lazy(() => import("./pages/other/Registro"));
const Recuperar = lazy(() => import("./pages/other/Recuperar"));

const helmetContext = {}

const App = (props) => {
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [objUser, setObjUser] = useState(false);

  useEffect(() => {
    props.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          es: require("./translations/spanish.json")
        }
      })
    );
  });

  return (
  <HelmetProvider context={helmetContext}>
    <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated, objUser, setObjUser }}>
    <ToastProvider placement="bottom-left">
      <BreadcrumbsProvider>
        <Router>
          <ScrollToTop>
            <Suspense
              fallback={
                <div className="flone-preloader-wrapper">
                  <div className="flone-preloader">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              }
            >
              <Switch>
                <Route
                  exact
                  path={process.env.PUBLIC_URL + "/"}
                  component={HomeIndex}
                />

                {/* Homepages */}
                <Route
                  path={process.env.PUBLIC_URL + "/home-index"}
                  component={HomeIndex}
                />

                {/* Shop pages - /:category?*/}              
                <Route
                  path={process.env.PUBLIC_URL + "/catalogo/:brand?"}
                  component={Catalogo}
                />


                {/* Shop product pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/product/:id"}
                  render={(routeProps) => (
                    <Product {...routeProps} key={routeProps.match.params.id} />
                  )}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/product-tab-left/:id"}
                  component={ProductTabLeft}
                />


                {/* Other pages */}
                <Route
                  path={process.env.PUBLIC_URL + "/about"}
                  component={About}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/contact"}
                  component={Contact}
                />
                <Route
                  path={process.env.PUBLIC_URL + "/nosotros"}
                  component={Nosotros}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/pago-aprobado"}
                  component={PagoAprobado}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/politicas"}
                  component={Politicas}
                />

                <Route
                  path={process.env.PUBLIC_URL + "/videos"}
                  component={Videos}
                />                

                <Route
                  path={process.env.PUBLIC_URL + "/cursos"}
                  component={Cursos}
                /> 

                <Route
                  path={process.env.PUBLIC_URL + "/eventos"}
                  component={Eventos}
                />                 

                <Route
                  path={process.env.PUBLIC_URL + "/login/:code?"}
                  render={(routeProps) => (
                    <Login {...routeProps} key={routeProps.match.params.code} />
                  )}
                />                  


                <Route
                  path={process.env.PUBLIC_URL + "/registro"}
                  component={Registro}
                />   

                <Route
                  path={process.env.PUBLIC_URL + "/recuperar"}
                  component={Recuperar}
                />                 

                <Route
                  path={process.env.PUBLIC_URL + "/cuenta"}
                  component={MiCuenta}
                />   



                {/* Not used */}

                <Route
                  path={process.env.PUBLIC_URL + "/not-found"}
                  component={NotFound}
                />


                <Route exact component={NotFound} />
              </Switch>
            </Suspense>
          </ScrollToTop>
        </Router>
      </BreadcrumbsProvider>
    </ToastProvider>
    </AppContext.Provider>
    </HelmetProvider>    
  );
};

App.propTypes = {
  dispatch: PropTypes.func
};


export default connect()(multilanguage(App));
