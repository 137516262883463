import axios from "axios";
export const SET_CURRENCY = "SET_CURRENCY";

export const setCurrency = (currencyName) => {
  return (dispatch) => {
    axios
      .get(
        `https://api.exchangeratesapi.io/v1/latest?access_key=9556740786a264326a029a434e1fcd95&base=MXN&symbols=USD,MXN,CAD,EUR`
      )
      .then((response) => {
        const rates = response.data.rates;
        let currencyRate = 0;
        for (const rate in rates) {
          if (rate === currencyName) {
            currencyRate = rates[rate];
          }
        }
        dispatch({
          type: SET_CURRENCY,
          payload: { currencyName, currencyRate }
        });
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  };
};
